.backToTopBtn {
    border: 0;
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 999;
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 30px;
    background: #f5f5f5;
    color: #797979;
    font-size: 20px;
    cursor: pointer;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    border-radius: 0.25rem;
  &:hover {
      background: #004aad;
      color: #fff;
  }
}