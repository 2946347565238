@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}
.spinner-container{
    right: 0;
    bottom: 0;
    top: 0;
    position: fixed;
    left: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(255 255 255 / 70%);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    .loading-spinner {
        width: 50px;
        height: 50px;
        border: 5px solid rgb(10 102 194 / 40%); /* Light grey */
        border-top: 5px solid #004aad; /* Black */
        border-radius: 50%;
        animation: spinner 1.5s linear infinite;
        margin: 0 auto;
    }
}

.loading-spinner-2 {
    display:inline-block;
    font-size:0px;
    padding:0px;
 }
 .loading-spinner-2 span {
    vertical-align:middle;
    border-radius:100%;
    
    display:inline-block;
    width:10px;
    height:10px;
    margin:3px 2px;
    -webkit-animation:loadingspinneranimated 0.8s linear infinite alternate;
    animation:loadingspinneranimated 0.8s linear infinite alternate;
 }
 .loading-spinner-2 span:nth-child(1) {
    -webkit-animation-delay:-1s;
    animation-delay:-1s;
   background:rgba(10, 102, 194,0.6);
 }
 .loading-spinner-2 span:nth-child(2) {
    -webkit-animation-delay:-0.8s;
    animation-delay:-0.8s;
   background:rgba(10, 102, 194,0.8);
 }
 .loading-spinner-2 span:nth-child(3) {
    -webkit-animation-delay:-0.26666s;
    animation-delay:-0.26666s;
   background:rgba(10, 102, 194,1);
 }
 .loading-spinner-2 span:nth-child(4) {
    -webkit-animation-delay:-0.8s;
    animation-delay:-0.8s;
   background:rgba(10, 102, 194,0.8);
   
 }
 .loading-spinner-2 span:nth-child(5) {
    -webkit-animation-delay:-1s;
    animation-delay:-1s;
   background:rgba(10, 102, 194,0.4);
 }
 
 @keyframes loadingspinneranimated {
    from {transform: scale(0, 0);}
    to {transform: scale(1, 1);}
 }
 @-webkit-keyframes loadingspinneranimated {
    from {-webkit-transform: scale(0, 0);}
    to {-webkit-transform: scale(1, 1);}
 }
 