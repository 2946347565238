.container-scroller {
    overflow: hidden;
    .page-body-wrapper {
        min-height: calc(100vh - 90px);
        display: flex;
        flex-direction: row;
        padding-left: 0;
        padding-right: 0;
        .main-panel {
            -webkit-transition: width .25s ease,margin .25s ease;
            transition: width .25s ease,margin .25s ease;
            margin-left: 260px;
            width: calc(100% - 260px);
            min-height: calc(100vh - 70px);
            display: flex;
            flex-direction: column;
            .content-wrapper {
                background: #fff;
                padding: 20px 20px 20px 20px;
                width: 100%;
                flex-grow: 1;
                .content-body{
                    background: #fff;
                    box-shadow: 0 4px 4px #e5e5e5;
                    border-radius: 5px;
                    padding: 1.2rem;
                }
            }
        }
    }
}
.navbar.fixed-top+.page-body-wrapper .main-panel{
    padding-top: 70px;
}
.d-col-title{
    font-weight: 600;
    font-size: 24px;
    color: #000000;
}
.d-col-input-field{
    .form-label{
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.5px;
        color: #333;
        margin-bottom: 0.2rem;
    }
    .form-control{
        background: #FFFFFF;
        //border: 1px solid rgba(131, 124, 124, 0.2);
        font-weight: 400;
        font-size: 16px;
        color: #000000;
        height: 50px;
        padding-left: 1rem;
        padding-right: 1rem;
        &.d-dateIcon{
            background-image: url('/assets/images/calendar-icon-2.png');
            background-position: center right 15px;
            background-repeat: no-repeat;
            background-size: 16px;
        }
    }
    textarea.form-control{
        height: inherit;
    }
    .form-select{
        //border: 1px solid rgba(131, 124, 124, 0.2);
        font-weight: 400;
        font-size: 16px;
        text-transform: capitalize;
        color: #000000;
        height: 50px;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .hint{
        display: block;
        font-weight: 400;
        font-size: 13px;
        letter-spacing: 0.5px;
        color: #BE8888;
        margin-top: 3px;
    }
    .d-col-input-field-close{
        position: relative;
        .close-btn{
            padding: 0;
            width: 20px;
            height: 20px;
            line-height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #A2BAC9;
            font-size: 20px;
            position: absolute;
            right: 1rem;
            top: 0;
            bottom: 0;
            margin:auto;
        }
    }
}
.d-col-radio-field{
    display: flex;
    align-items: center;
    .form-check{
        margin-right: 15px;
        .form-check-input[type=radio]{
            height: 18px;
            width: 18px;
            // border: 1px solid #ced4da;
            position: relative;
            &:checked {
                background-color: #fff;
                border-color: #004aad;
                background-image: none;
                &:after{
                    content:"";
                    width: 8px;
                    height: 8px;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    background-color: #004aad;
                    border-radius: 50%;
                }
            }
        }
        .form-check-label{
            font-weight: 400;
            font-size: 15px;
            text-transform: capitalize;
            color: #000000;
            padding-left: 5px;
        }
        .cityinput-item-add {
            width: 26px;
            height: 26px;
            padding: 0;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
        }
    }
}
.d-col-number-field{
    border: 1px solid #ced4da;
    background-color: #fff;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    height: 50px;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
    max-width: 140px;
    .react-numeric-input{
        b{
            border-color: transparent !important;
            background: transparent !important;
        }
        input{
            font-weight: 400;
            font-size: 15px!important;
            border: 0 !important;
            height: 36px;
            width: 100%;
            &:focus{
                outline: 0;
            }
        }
    }
    .form-select{
        border: 0;
        &:focus{
            box-shadow: none;
        }
    }
}
.d-span-row{
    display: flex;
    flex-wrap: wrap;
    .d-col-8{
        width: 63%;
        padding-right: 1.5rem;
        border-right: 1px solid #E7E7E7;
    }
    .d-col-4{
        width: 37%;
        padding-left: 1.5rem;
    }
    .d-col-7{
        width: 58.33333333%;
        padding-right: 1.5rem;
        border-right: 1px solid #E7E7E7;
    }
    .d-col-5{
        width: 41.66666667%;
        padding-left: 1.5rem;
    }
}
.d-nav-tabs-title{
    font-weight: 600;
    font-size: 17px;
    letter-spacing: 0.5px;
    color: #000000;
}
.d-nav-tabs{
    margin-right: -8px;
    .nav-item{
        padding-right: 8px;
        margin-top: 8px;
        .nav-link{
            background: #E2F1FA;
            border-radius: 0.25rem;
            font-weight: 400;
            font-size: 15px;
            letter-spacing: 0.5px;
            color: #778E9C;
            min-width: 120px;
            position: relative;
            padding: 4px 8px;
            span{
                display: block;
                font-weight: 400;
                font-size: 12px;
                letter-spacing: 0.5px;
                color: #778E9C;
            }
            &.active{
                background: #004aad;
                color: #fff;
                span{
                    color: #fff;
                }
            }
            .bi{
                position: absolute;
                right: 8px;
                top: 5px;
                font-size: 16px;
                color: #A2BAC9;
                cursor: pointer;
                &:hover{
                    color:#004aad;
                }
            }
        }
        .day-item-add{
            width: 26px;
            height: 26px;
            font-size: 14px;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &:last-child{
            display: flex;
            align-items: center;
            margin-right: 0;
        }
    }
}
.d-nav2-tabs{
    margin-top: 8px;
    margin-right: -8px;
    .nav-item{
        padding-right: 8px;
        margin-top: 8px;
        &:last-child{
            margin-right: 0;
        }
        .nav-link{
            background: #E2F1FA;
            border-radius: 0.25rem;
            font-weight: 400;
            font-size: 15px;
            letter-spacing: 0.5px;
            color: #778E9C;
            padding: 5px 10px;
            min-width: 130px;
            &.active,
            &:hover{
                background: #004aad;
                color: #fff;
                span{
                    color: #fff;
                }
                .add-day-t{
                    i{
                        &.icon-Transpott{
                         background-image: url('/assets/images/transpott-icon-white.png');
                        }
                        &.icon-Stays{
                         background-image: url('/assets/images/stays-icon-white.png');
                        }
                        &.icon-Activity{
                         background-image: url('/assets/images/activity-icon-white.png');
                        }
                        &.icon-Tours{
                         background-image: url('/assets/images/tours-icon-white.png');
                        }
                        &.icon-Food{
                         background-image: url('/assets/images/food-icon-white.png');
                        }
                        &.icon-Others{
                         background-image: url('/assets/images/others-icon-white.png');
                        }
                    }
                 }
            }
            &.disabled{
                pointer-events: inherit;
            }
        }
        .add-day-t{
            background-image: url('/assets/images/plus-circle-icon.png');
            background-repeat: no-repeat;
            background-position: center left;
            padding-left: 36px;
            background-size: 28px;
            span{
               display: block;
                font-weight: 600;
                font-size: 13px;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                color: #94A8B5;
            }
            i{
                width: 45px;
                height: 24px;
                background-repeat: no-repeat;
                background-position: center left;
                display: block;
                &.icon-Transpott{
                    background-image: url('/assets/images/transpott-icon.png');
                    background-size: 34px;
                }
                &.icon-Stays{
                    background-image: url('/assets/images/stays-icon.png');
                    background-size: 26px;
                }
                &.icon-Activity{
                    background-image: url('/assets/images/activity-icon.png');
                    background-size: 17px;
                }
                &.icon-Tours{
                    background-image: url('/assets/images/tours-icon.png');
                    background-size: 16px;
                }
                &.icon-Food{
                    background-image: url('/assets/images/food-icon.png');
                    background-size: 17px;
                }
                &.icon-Others{
                    background-image: url('/assets/images/others-icon.png');
                    background-size: 16px;
                }
            }
        }
    }
}
.d-col-number-group{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .number-form-label{
        p{
            font-weight: 600;
            font-size: 15px;
            letter-spacing: 0.5px;
            color: #222;
            white-space: nowrap;
            line-height: 20px;
            small{
                font-weight: 400;
                font-size: 13px;
                color: #444;
            }
        }
    }
    .d-col-number-select{
        .form-control{
            height: 42px;
            font-size: 14px;
            max-width: 180px;
            min-width: 90px;
        }
    }
    .d-col-type-bx{
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            max-width: 100%;
        }
    }
}
.d-col-number-days{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #ced4da;
    background-color: #fff;
    width: 100%;
    border-radius: 0.25rem;
    .form-label{
        margin-bottom: 0;
        padding-left: 1rem;
        font-weight: 400;
    }
    .d-col-number-field{
        border: 0;
        max-width: 100px;
        .react-numeric-input{
            input{
                text-align: right;
            }
        }
    }
}
.time-list-item{
    display: flex;
    .time-text{
        position: relative;
        width: 90px;
        padding-bottom: 20px;
        &:after{
            content: '';
            width: 16px;
            height: 16px;
            background: #E2F1FA;
            position: absolute;
            right: -10px;
            top: 6px;
            border-radius: 50%;
        }
        span{
            font-weight: 400;
            font-size: 15px;
            line-height: 26px;
            letter-spacing: 0.5px;
            color: #000000;
        }
    }
    .time-description{
        font-weight: 500;
        font-size: 20px;
        letter-spacing: 0.5px;
        color: #000000;
        padding-left: 20px;
        padding-bottom: 20px;
        width: calc(100% - 90px);
        position: relative;
        &:before{
            content: '';
            width: 3px;
            height: 100%;
            background: #E2F1FA;
            position: absolute;
            left: 0;
            top: 6px;
        }
    }
    &:last-child{
        .time-description{
            &::before{
                display: none;
            }
        }
    }
}
.mt-header-block{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn{
        height: 42px;
        min-width: 90px;
        font-weight: 600;
        font-size: 14px;
        text-align: center;
        text-transform: uppercase;
        i{
            margin-right: 3px;
        }
    }
}
.mt-table-wrap{
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    .table > :not(:first-child) {
        border-top: 0;
    }
    .table{
        margin-bottom: 0;
        thead{
            th{
                font-weight: 600;
                font-size: 14px;
                letter-spacing: 0.5px;
                color: #000000;
                padding: 8px;
                border-bottom-color:#ced4da;
                background-color: #fff;
                white-space: nowrap;
                &:last-child{
                    text-align: right;
                }
            }
        }
        tbody{
            td{
                font-weight: 400;
                font-size: 15px;
                letter-spacing: 0.5px;
                color: #000000;
                border-top: 1px solid #ced4da;
                background-color: #fff;
                border-bottom: 0;
                padding: 8px;
                vertical-align: middle;
                &.mt-btn-groups{
                    text-align: right;
                    .btn{
                        background-color: #FFFFFF;
                        box-shadow: 0px 0px 10px rgb(0 0 0 / 15%);
                        width: 36px;
                        height: 36px;
                        margin-left: 6px;
                        font-size: 16px;
                        color: #004aad;
                        padding: 0;
                        &:hover{
                            background-color: #004aad;
                            color: #ffffff;
                        }
                    }
                }
            }
        }
    }
}
.passport-img-row{
    display: flex;
    max-width: 180px;
    margin-top: 5px;
    margin-left: -4px;
    margin-right: -4px;
    span{
        width: 48px;
        padding: 0 4px;
        display: block;
        img{
            border-radius: 0.25rem;
            max-height: 34px;
            min-height: 34px;
            object-fit: cover;
        }
    }
}

.add-travellers-modal{
    .modal-content {
        border: 0;
        .modal-header{
            padding: 1rem 1.2rem;
            .modal-title{
                font-size: 18px;
                font-weight: 600;
                color: #000;
            }
        }
        .modal-body {
            padding: 1.2rem;
        }    
        .modal-footer{
            padding: 1rem 1.2rem;
            .btn{
                min-width: 100px;
                font-size: 15px;
                height: 50px;
            }
        }
    }
    .upload-image-btn {
        position: relative;
        overflow: hidden;
        font-size: 11px;
        color: #8C8C8C;
        background-color: #FFFFFF;
        width: 100%;
        height: 60px;
        border-radius: 0.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 1px solid #ced4da;
        text-align: center;
        i{
            font-size: 18px;
            line-height: 0;
        }
        input {
            position: absolute;
            font-size: 30px;
            opacity: 0;
            right: 0;
            top: 0;
        }
    }
    .ps-img-box{
        position: relative;
        margin-top: 5px;
        border-radius: 0.25rem;
        border: 1px solid #ced4da;
        height: 100px;
        width: 100%;
        overflow: hidden;
        .ps-close-btn{
            position: absolute;
            right: 4px;
            top: 4px;
            padding: 0;
            color: #fff;
            font-size: 16px;
            width: 18px;
            height: 18px;
            line-height: 18px;
            border-radius: 50%;
            opacity: 0.7;
            background-color: #004aad;
            &:focus,
            &:hover{
                box-shadow: none;
                opacity: 1;
                background-color: #004182;
            }
        }
        .ps-img-upload-fluid{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
.form-check-group{
    .form-check{
        display: flex;
        .form-check-input[type=checkbox]{
            height: 18px;
            width: 18px;
            min-width: 18px;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            &:checked {
                background-color: #004aad;
                border-color: #004aad;
            }
        }
        .form-check-label{
            font-weight: 400;
            font-size: 15px;
            color: #262626;
            padding-left: 5px;
        }
    }
}
.form-btn-groups{
    .btn{
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        min-width: 100px;
        height: 50px;
        font-size: 15px;
        &.ml-20{
            margin-left: 10px;
        }
    }
}
.tour-item-wrap{
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .tour-item-title{
        width: calc(100% - 38px);
        h4{
            font-weight: 700;
            color: #000;
            font-size: 18px;
        }
        p{
            font-weight: 500;
            font-size: 14px;
            color: #262626;
            margin-top: 3px;
            span{
                color: rgba(131, 124, 124, 0.4);
                display: inline-block;
                margin: 0 2px;
            }
        }
    }
    
    .btn{
        width: 34px;
        height: 34px;
        border-radius: 50%;
        background: #FFFFFF;
        border: 1px solid #DBDBDB;
        box-shadow: 0px 4px 11px rgba(197, 187, 187, 0.2);
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('/assets/images/edit-icon.png');
        background-size: 15px;
    }
}
.add-destination-item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-top: -8px;
    .a-d-left-bx{
        display: flex;
        width: calc(100% - 42px);
        .a-d-icon{
            display: inline-block;
            width: 46px;
            height: 46px;
            border: 2px solid rgb(219, 219, 219);
            border-radius: 50%;
            background-repeat: no-repeat;
            background-position: center;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            &.flight-icon{
                background-image: url('/assets/images/flight-icon.png');
            }
            &.car-icon{
                background-image: url('/assets/images/car-icon.png');
            }
            &.stay-icon{
                background-image: url('/assets/images/stay-icon.png');
            }
            &.restaurant-icon{
                background-image: url('/assets/images/restaurant-icon.png');
            }
            &.activity-icon{
                background-image: url('/assets/images/activity-icon-black.png');
            }
        }
        .a-d-item-info{
            width: calc(100% - 46px);
            padding-left: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            h5{
                font-weight: 400;
                font-size: 16px;
                letter-spacing: 0.5px;
                color: #000000;
            }
            p{
                font-weight: 400;
                font-size: 14px;
                letter-spacing: 0.5px;
                color: #6A6A6A;
                margin-top: 0.2rem;
                span.a-d-type{
                    color: #004aad;
                    border-radius: 20px;
                    padding: 2px 7px;
                    font-size: 11px;
                    display: inline-block;
                    background: #e2f1fa;
                    font-weight: 500;
                }
            }
        }
    }
    .a-d-item-actions{
        display: flex;
        align-items: center;
        .btn{
            margin-left: 8px;
            width: 38px;
            height: 38px;
            border-radius: 50%;
            background: #FFFFFF;
            border: 2px solid #DBDBDB;
            box-shadow: 0px 4px 8px rgba(197, 187, 187, 0.2);
            background-repeat: no-repeat;
            background-position: center;
            &.edit-icon{
                background-image: url('/assets/images/edit-icon.png');
                background-size: 16px;
            }
            &.delete-icon{
                background-image: url('/assets/images/delete-icon.png');
                background-size: 16px;
            }
        }
    }
}
.Destination-flex-bx{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .Destination-flexright{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .Destination-fields{
            display: flex;
            align-items: center;
            margin-left: 15px;
            .form-label{
                font-weight: 400;
                font-size: 16px;
                letter-spacing: 0.5px;
                color: #373737;
                margin-bottom: 0;
                margin-right: 10px;
            }
            .form-control{
                max-width: 85px;
            }
            .d-col-number-field {
                max-width: 85px;
            }
        }
    }
}
.civ-item-bx{
    .civ-day-date{
        background: #E2F1FA;
        border-radius: 0.25rem;
        color: #778E9C;
        position: relative;
        padding: 8px;
        margin-bottom: 10px;
        span{
            display: block;
            font-weight: 400;
            font-size: 12px;
            letter-spacing: 0.5px;
            color: #778E9C;
            &.civ-day-txt{
                font-size: 17px;
            }
        }
    }
   
    .civ-list-item{
        background: #FFFFFF;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
        border-radius: 0.25rem;
        padding: 8px;
        margin-top: 12px;
        .civ-item-top-flex{
            display: flex;
            .civ-item-img{
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: 0.25rem;
                width: 70px;
                height: 70px;
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .civ-item-info{
                width: calc(100% - 80px);
                padding-left: 10px;
                .civ-item-icon-check{
                    margin-bottom: 3px;
                    span{
                        background: #E2F1FA;
                        border-radius: 100px;
                        font-weight: 600;
                        font-size: 10px;
                        letter-spacing: 0.5px;
                        text-transform: uppercase;
                        color: #778E9C;
                        display: inline-flex;
                        align-items: center;
                        padding: 3px 10px;
                        img{
                            margin-right: 4px;
                        }
                    }
                }
                h5{
                    font-weight: 600;
                    font-size: 14px;
                    letter-spacing: 0.5px;
                    color: #000000;
                }
                p{
                    font-weight: 500;
                    font-size: 13px;
                    color: #000000;
                    margin-top: 3px;
                    &.civ-time-count{
                        background-position: center left;
                        background-repeat: no-repeat;
                        background-image: url('/assets/images/Time-icon.png');
                        padding-left: 16px;
                        background-size: 12px;
                        font-style: italic;
                        font-size: 13px;
                        color: #555;
                    }
                }
            }
        }
    }
}
.dash-item-bx{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 140px;
    border-radius: 5px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    &:hover{
        background-color:#004aad!important;
        .dash-item-title{
            color: #fff!important;
        }
        .dash-item-count{
            color: #fff!important;
        }
    }
    .dash-item-title{
        font-weight: 600;
        font-size: 17px;
    }
    .dash-item-count{
        font-weight: 700;
        font-size: 30px;
    }
    &.dash-item-bg-1{
        background-color:#DFE5FA;
        .dash-item-title{
            color: #004aad;
        }
        .dash-item-count{
            color: #004aad;
        }
    }
    &.dash-item-bg-2{
        background-color:#DFE5FA;
        .dash-item-title{
            color: #004aad;
        }
        .dash-item-count{
            color: #004aad;
        }
    }
    &.dash-item-bg-3{
        background-color:#DFE5FA;
        .dash-item-title{
            color: #004aad;
        }
        .dash-item-count{
            color: #004aad;
        }
    }
}
.listitenaries-item{
    // background: #FFFFFF;
    // box-shadow: 0 4px 4px #e5e5e5;
    // border-radius: 5px;
    // padding: 20px;
    .listitenaries-top-bx{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 12px;
        .listitenaries-item-title{
            width: calc(100% - 100px);
            .listitenaries-cust{
                font-weight: 500;
                color: #fff;
                font-size: 10px;
                display: inline-block;
                margin-bottom: 5px;
                background-color: #004aad;
                border-radius: 15px;
                padding: 1px 6px;
            }
            h4{
                font-weight: 600;
                color: #000;
                font-size: 16px;
            }
            p{
                font-weight: 500;
                font-size: 14px;
                color: #262626;
                margin-top: 2px;
            }
        }
        .listitenaries-item-btns{
            display: flex;
            align-items: center;
            .btn{
                width: 38px;
                height: 38px;
                border-radius: 50%;
                background: #FFFFFF;
                border: 1px solid #DBDBDB;
                box-shadow: 0px 10px 11px rgba(197, 187, 187, 0.2);
                background-repeat: no-repeat;
                background-position: center;
                margin-left: 10px;
                font-size:16px;
                color: #979797;
                padding: 0;
                &.edit-icon{
                    background-image: url('/assets/images/edit-icon.png');
                    background-size: 16px;
                }
                &.delete-icon{
                    background-image: url('/assets/images/delete-icon.png');
                    background-size: 16px;
                }
            }
        }
    }
    .listitenaries-bottom-bx{
        h5{
            font-weight: 700;
            color: #000;
            font-size: 20px;
        }
        p{
            font-weight: 400;
            font-size: 16px;
            color: #262626;
            margin-top: 5px;
            .readmore{
                font-weight: 600;
                color: #004aad;
                cursor: pointer;
            }
        }
    }
}

.listitenaries-img-ul{
    display: flex;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
    position: relative;
    li{
        padding: 0 5px;
        width: 12.5%;
        display: none;
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8){
            display: inline-block;
        }
        &.lit-img-count-item{
            position: absolute;
            z-index: 1;
            right: 0;
            top: 0;
            bottom: 0;
            display: inline-block;
            .lit-img-count-item-in{
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                background-color: rgb(0 0 0 / 50%);
                border-radius: 5px;
                span{
                    font-size: 16px;
                    font-weight: 500;
                    color: #fff;
                }
            }
        }
        .listitenaries-img-bx{
            border-radius: 5px;
            overflow: hidden;
            height: 90px;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}
.oneway-number-field{
    .d-col-number-days{
        .d-col-number-field {
            max-width: 75px;
        }
    }
    .hint{
        display: block;
        font-weight: 400;
        font-size: 13px;
        letter-spacing: 0.5px;
        color: #BE8888;
        margin-top: 3px;
    }
}
.day-list-item{
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 10px;
    margin-top: 15px;
    .day-item-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding:10px;
        border-bottom: 1px solid #E5E5E5;
        .day-item-title{
            font-weight: 500;
            font-size: 24px;
            letter-spacing: 0.5px;
            color: #000000;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .dh-block-right{
            display: flex;
            align-items: center;
            .upload-image-btn {
                position: relative;
                overflow: hidden;
                font-weight: 400;
                font-size: 17px;
                color: #004aad;
                margin-right: 5px;
                i{
                    font-size: 26px;
                    vertical-align: middle;
                    margin-right: 5px;
                }
                input {
                    position: absolute;
                    font-size: 50px;
                    opacity: 0;
                    right: 0;
                    top: 0;
                }
            }
            .btn-minus,
            .btn-plus{
                border: 1px solid transparent;
                background-color: #fff;
                border-radius: 50%;
                font-size: 20px;
                width: 36px;
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 10px;
            }
            .btn-minus{
                border-color: #D3D3D3; 
                color: #D3D3D3;
            }
            .btn-plus{
                border-color: #004aad; 
                color: #004aad;
            }
        }
        
          
    }
    .day-item-btm-bx{
        display: flex;
        padding: 10px;
        .dh-btm-left{
            width: 50%;
            padding-right: 10px;
            p{
                font-weight: 400;
                font-size: 18px;
                letter-spacing: 0.5px;
                color: #000000;
            }
        }
        .dh-btm-right{
            width: 50%;
            display: flex;
            .dh-images-list{
                width: 50%;
                .dh-image-ul{
                    display: flex;
                    flex-wrap: wrap;
                    margin-left: -3px;
                    margin-right: -3px;
                    li{
                        margin-top: 6px;
                        padding: 0 3px;
                        width: 50%;
                        &:nth-child(1),
                        &:nth-child(2){
                            margin-top: 0;
                        }
                        .dh-image-item{
                            overflow: hidden;
                            border-radius: 10px;
                            height: 90px;
                            position: relative;
                            img{
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                            i{
                                position: absolute;
                                right: 5px;
                                top: 5px;
                                z-index: 1;
                                width: 16px;
                                height: 16px;
                                line-height: 16px;
                                font-size: 16px;
                                color: #fff;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
            .dh-maps{
                width: 50%;
                height: 185px;
                padding-left: 8px; 
                overflow: hidden;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}
.d-type-item-bx{
    h5{
        font-weight: 500;
        font-size: 17px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: #000000;
    }
    .d-type-ul{
        margin-left: -7px;
        margin-right: -7px;
        li{
            display: inline-block;
            padding-left: 7px;
            padding-right: 7px;
            margin-top: 7px;
            .d-type-item{
                overflow: hidden;
                position: relative;
                input{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    opacity: 0;
                    &:checked + span{
                        background-color: #004aad;
                        color: #fff;
                    }
                }
               span{
                    background: #E2F1FA;
                    border-radius: 10px;
                    display: block;
                    padding: 10px 15px;
                    font-weight: 400;
                    font-size: 15px;
                    letter-spacing: 0.5px;
                    color: #778E9C;
                    min-width: 100px;
                    text-align: center;
               }
            }
        }
    }
}
.manage-profile-image{
    width: 100px;
    height: 100px;
    background: #fff;
    border: 1px solid #B8B8F5;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    img{
      width: 90px;
      height: 90px;
      border-radius: 50%;
      object-fit: cover;
    }
    .profile-img{
        width: 90px;
        height: 90px;
        border-radius: 50%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }
    .upload-profileimage-btn {
        z-index: 1;
        position: absolute;
        top: 5px;
        right: 0;
        overflow: hidden;
        font-size: 13px;
        color: #004aad;
        background-color: #FFFFFF;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        input {
            position: absolute;
            font-size: 30px;
            opacity: 0;
            right: 0;
            top: 0;
        }
    }
}
.c-password-input{
    position: relative;
    .eye-btn {
        border: 0;
        width: 40px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 8px;
        font-size: 20px;
        color: #555555;
        background-color: transparent;
    }
}
.qs-content-label{
    p{
        margin-bottom: 0.5rem;
        font-size: 14px;
        color: #777;
        &:last-child{
            margin-bottom: 0;
        }
    }
}
.btn-mt-actions{
    padding: 0.5rem 0;
    max-width: 130px;
    .btn{
        width:100%;
        text-align: left;
        color: #000000;
    }
}
.delete-travellers-modal{
    p{
        font-weight: 500;
        font-size: 16px;
        color: #000000;
        text-align: center;
    }
    .btn{
        min-width: 90px;
        font-size: 14px;
        font-weight: 500;
        height: 40px;
        border-radius: 0.25rem;
    }
}
.view-travellers-modal{
    p{
        font-size: 15px;
        text-align: left;
    }  
}
.custom-tooltip-color{
    > .tooltip-inner {
        background-color: #004aad;
    }
    &.tooltip[data-popper-placement^='left']{
        .tooltip-arrow:before{
            border-left-color:#004aad !important;
        }
    }
}
.profile-address-field{
    position: relative;
    .autocomplete-dropdown-container{
        max-height: 130px;
    }
}
.hotel-stays-row{
    .hotel-list-item {
        .hotel-price-block { 
            justify-content: space-between;
            flex-direction: column;
            .date-pos{
                p{
                    font-size: 13px;
                    color: #777;
                    margin-bottom: 0;
                    span{
                        color: #14183E;
                        font-weight: 600;
                        font-size: 13px;
                        white-space: nowrap;
                    }
                    text-align: right;
                }
            }
            .price-pos{
                .night{
                    font-size: 13px;
                    margin-top: 0.5rem;
                    .badge{
                        font-size: 10px;
                    }
                }
            }
        }
    }
}
.btnrm-field{
    .btn{
        color:#fff;
        margin-left: 0.5rem;
        margin-top: 5px;
        font-size: 14px;
        &:first-child{
            margin-left: 0;
        }
    }
}

.btn-crt-itinaries{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    .btn{
        min-width: 100px;
    }
}
.btn-height-58{
    height: 50px;
    font-size: 15px;
    font-weight: 600;
}
.srws-item-lg{
    position: relative;
    overflow: hidden;
    border-radius: 0.25rem;
    height: 100%;
    .srws-item-radio{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
    }
    .srws-item-radio:checked + .srws-item{
        border-color: #004aad;
        background-color: rgb(233 233 233);
    }
    .srws-item{
        display: flex;
        align-items: flex-start;
        border: 1px solid #ced4da;
        padding: 0.6rem;
        border-radius: 0.25rem;
        height: 100%;
        .srws-item-img{
            width: 44px;
            height: 44px;
            border-radius: 0.25rem;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .srws-item-info{
            width: calc(100% - 52px);
            padding-left: 8px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .srws-item-head{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 5px;
                h5{
                    font-weight: 400;
                    font-size: 16px;
                    letter-spacing: 0.5px;
                    color: #000000;
                    width: calc(100% - 40px);
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .srws-item-rating{
                    background-color: #30994D;
                    color: #fff;
                    border-radius: 0.25rem;
                    font-size: 10px;
                    padding: 2px 4px;
                    i{
                        font-size: 8px;
                    }
                }
            }
            p{
                font-weight: 400;
                font-size: 14px;
                letter-spacing: 0.5px;
                color: #6A6A6A;
                line-height: 18px;
            }
        }
    }
}
.food-radio-style{
    .form-check{
        margin-right: 0.8rem;
        .form-check-input {
            margin-top: 0.35em;
            border-color: rgba(0, 0, 0, 0.4);
        }
        .form-check-label{
            font-size: 15px;
            color: #333;
        }
    }
}
.food-select-modal{
    .modal-content {
        .modal-footer{
            justify-content: space-between;
            .ml-3{
                margin-left: 1rem;
            }
        }
    }
    
}
.custom-add-rm-btn{
    input[type=number]{
        width: 100%;
        border: 0;
        text-align: center;
        font-weight: 400;
        font-size: 15px;
        -moz-appearance: textfield;
        &:focus{
            outline: 0;
        }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    button{
        background-color: #fff;
        border:0;
        font-size: 19px;
        font-weight: 700;
        &:focus{
            outline: 0;
            border:0;
        }
    }
}
.custom-daysAdd-Rm-btn{
    input[type=number]{
        width: 40px;
        border: 0;
        text-align: center;
        font-weight: 400;
        font-size: 18px;
        -moz-appearance: textfield;
        &:focus{
            outline: 0;
        }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    .daysAdd-minus-btns{
        width: 24px;
        button{
            background-color: #fff;
            border:0;
            font-size: 17px;
            font-weight: 700;
            height: 24px;
            &:focus{
                outline: 0;
                border:0;
            }
        }
    }
   
}
.food-footer-lt{
    width: 295px;
    .cost-item-field{
        .form-control{
            max-width: 100%;
        }
    }
}
.add-activity-search-box{
    display: flex;
    justify-content: space-between;
    max-width: 295px;
    .d-col-input-field{
        width: calc(100% - 58px);
        .form-select{
            height:48px;
        }
    }
    .btn{
        width: 48px;
        height:48px;
        font-size: 17px;
    }
}

.multi-city-row{
    display: flex;
    justify-content: space-between;
    border: 1px solid #E5E5E5;
    border-radius: 0.25rem;
    .multi-city-item{
        width: 33.3333%;
        padding: 5px;
        .d-col-input-field {
            .form-select{
                font-size: 16px;
                height: 46px;
                color: #555;
                padding-right: 2rem;
                text-overflow: ellipsis;
            }
            .form-control{
                font-size: 16px;
                height: 46px;
                color: #555;
                &.d-dateIcon{
                    background-size: 16px;
                }
            }
        }
    }
    &.rs-multi-city{
        position: relative;
        padding-left: 20px;
        padding-right: 32px;
        .multi-city-count{
            width: 20px;
            background-color: #E5E5E5;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0.25rem 0 0 0.25rem;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
        }
        .multi-city-item-delete{
            width: 32px;
            border-left: 1px solid#E5E5E5;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            .close-btn{
                width: 30px;
                height: 30px;
                padding: 0;
                font-size: 17px;
            }
        }
    }
}
.create-itinarary-wrap{
    margin-bottom: 4rem;
}
.create-itinarary-wrap .was-validated .form-check-input:valid ~ .form-check-label, 
.create-itinarary-wrap .form-check-input.is-valid ~ .form-check-label{
    color: #373737;
}
.create-itinarary-wrap .was-validated .form-control:valid, 
.create-itinarary-wrap .form-control.is-valid {
    border-color: rgba(131, 124, 124, 0.4);
    background-image: none;
    padding-right: 1rem;
}
.draft-btn-right{
    display: flex;
    align-items: center;
    .btn{
        width: 38px;
        height: 38px;
        border-radius: 50%;
        background: #FFFFFF;
        border: 1px solid #ced4da;
        box-shadow: 0px 10px 11px rgba(197, 187, 187, 0.2);
        background-repeat: no-repeat;
        background-position: center;
        margin-left: 10px;
        font-size:16px;
        color: #979797;
        padding: 0;
        &.edit-icon{
            background-image: url('/assets/images/edit-icon.png');
            background-size: 16px;
        }
        &.delete-icon{
            background-image: url('/assets/images/delete-icon.png');
            background-size: 16px;
        }
    }
}
.days-count-nav{
    .nav-item{
        &:first-child{
            .nav-link {
                .bi{
                    display: none;
                }
            }
        }
    }
}
.ci-buttons-bar{
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 10px 50px;
    margin-left: 260px;
    width: calc(100% - 260px);
    height: 68px;
    background-color: #fff;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.30);
}
.llp-item{
    width: 260px;
    display: flex;
    .civ-item-img{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 0.25rem;
        width: 70px;
        height: 70px;
        overflow: hidden;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .civ-item-info{
        width: calc(100% - 80px);
        padding-left: 10px;
        .civ-item-icon-check{
            margin-bottom: 3px;
            span{
                background: #E2F1FA;
                border-radius: 100px;
                font-weight: 600;
                font-size: 10px;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                color: #778E9C;
                display: inline-flex;
                align-items: center;
                padding: 3px 10px;
                img{
                    margin-right: 4px;
                }
            }
        }
        h5{
            font-weight: 600;
            font-size: 14px;
            letter-spacing: 0.5px;
            color: #000000;
        }
        p{
            font-weight: 500;
            font-size: 13px;
            color: #000000;
            margin-top: 3px;
            &.civ-time-count{
                background-position: center left;
                background-repeat: no-repeat;
                background-image: url('/assets/images/Time-icon.png');
                padding-left: 16px;
                background-size: 12px;
                font-size: 13px;
                font-style: italic;
                color: #555;
            }
        }
    }
}
.custom-badge-style{
    // background: #F44336;
    border-radius: 15px;
    font-size: 10px;
    padding: 2px 8px;
    color: #fff;
    vertical-align: middle;
}
.ci-other-img-field{
    .ci-other-img-block{
        position: relative;
        border-radius: 0.25rem;
        height: 80px;
        width: 80px;
        overflow: hidden;
        border: 1px solid #ced4da;
        margin-right: 0.7rem;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .ci-other-upload-btn{
        position: relative;
        overflow: hidden;
        font-size: 13px;
        color: #8C8C8C;
        background-color: #FFFFFF;
        padding: 6px 10px;
        border-radius: 0.25rem;
        border: 1px solid #ced4da;
        display: inline-block;
        input {
            position: absolute;
            font-size: 30px;
            opacity: 0;
            right: 0;
            top: 0;
        }
    }
}
body.modal-open{
    .pac-container{
        z-index: 10001 !important;
    }
}
.ci-iti-modal-footer{
    justify-content: space-between;
    .food-footer-rt{
        .errors-ul{
            margin-left: 15px;
            li{
                font-size: 12px;
                list-style: circle;
            }
        }
    }
}
.nav-multicity-tabs{
    .nav-item{
        margin-right: 0.5rem;
        .nav-link{
            color: #333;
            background-color: #E2F1FA;
            font-weight: 600;
            &.active{
                background-color: #004aad;
                color: #ffffff;
            }
        }
    }
}
.publish-modal-style{
    .d-col-radio-field{
        .form-check{
            border-bottom: 1px solid #E5E5E5;
            padding-top: 8px;
            padding-bottom: 8px;
            .form-check-input {
                margin-top: 0.15em;
            }
            .form-check-label{
                font-size: 13px;
                text-transform: inherit;
                h6{
                    font-size: 15px;
                }
            }
        }
    }
    .btn{
        font-size: 14px;
        height: 40px;
        min-width: 80px;
    }
}
.btn-width-30{
    width: 30px;
    height: 30px;
    padding: 0;
}
.found-txt{
    font-size: 12px;
    color: #778E9C;
}
.coming-soon-style{
    position: relative;
    .badge{
        position: absolute;
        top: -8px;
        right: 4px;
        font-size: 9px;
        border-radius: 15px;
        font-weight: 500;
    }
}
.txt-cost{
    font-weight: 600;
    color: #000000;
}
.sd-date-field{
    position: relative;
    .form-control{
        padding-left: 2.2rem;
    }
    .sd-date-icon{
        position: absolute;
        left: 12px;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 16px;
        height: 16px;
        z-index: 1;
        background-image: url('/assets/images/calendar-icon-2.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 15px;
        cursor: pointer;
    }
}

@media (min-width: 576px){
    .delete-travellers-modal{
        .modal-dialog{
            max-width: 340px;
        }
    }
}
@media (min-width: 992px){
    
}
@media (min-width: 1400px){
  
}

@media only screen and (max-width : 1200px){
    .d-span-row{
        .d-col-8{
            width: 100%;
            padding-right: 0;
            border-right: 0;
        }
        .d-col-4{
            width: 100%;
            padding-left: 0;
            margin-top: 1.5rem;
        }
    }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 991px) {
    .container-scroller {
        .page-body-wrapper {
            min-height: 100vh;
            .main-panel {
                width:100%;
                min-height: 100vh;
                margin-left: 0;
                .content-wrapper {
                    padding: 1.5rem 15px 15px 15px;
                    .content-body{
                        padding: 1.2rem;
                    }
                }
            }
        }
    }
    .navbar.fixed-top+.page-body-wrapper .main-panel{
        padding-top: 70px;
    }
    .ci-buttons-bar{
        margin-left: 0;
        width: 100%;
    }
    .ci-iti-modal-footer {
        flex-direction: column;
        .food-footer-lt{
            width:100%;
        }
        .food-footer-rt{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }
    }
}

@media only screen and (max-width : 767px) {
    .d-col-input-field{
        .form-control{
            height: 48px;
        }
        .form-select{
            height: 48px;
        }
    }
    .d-col-number-field{
        height: 48px;
        .react-numeric-input{
            width: 100%;
            input{
                height: 30px;
                font-size: 17px!important;
            }
        }
    }
    .custom-daysAdd-Rm-btn {
        .daysAdd-minus-btns {
            height: 48px;
            button{
                height: 23px;
            }
        }
    }
    .d-nav2-tabs{
        .nav-item{
            width: 33.3333%;
        }
    }
    .container-scroller {
        .page-body-wrapper {
            .main-panel {
                .content-wrapper {
                    .content-body{
                        padding: 1rem;
                    }
                }
            }
        }
    }
    .d-nav-tabs-title{
        font-size: 16px;
    }
    .d-nav-tabs{
        .nav-item{
            width: 33.333%;
            &:last-child{
                width: 100%;
                justify-content: center;
            }
        }
    }
    .time-list-item{
        .time-text{
            width: 50px;
            span{
                font-size: 13px;
            }
        }
        .time-description{
            width: calc(100% - 50px);
        }
    }
    .listitenaries-item{
        .listitenaries-top-bx{
            display: block;
            .listitenaries-item-title{
                width: 100%;
                h4{
                    font-size: 15px;
                }
                p{
                    font-size: 13px;
                }
            }
            .listitenaries-item-btns{
                display: flex;
                align-items: center;
                .btn{
                    margin: 10px 10px 0 0;
                }
            }
        }
    }
    .listitenaries-img-scroller{
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        .listitenaries-img-ul{
            display: inline-block;
            li{
                width: 90px;
                height: 70px;
                .listitenaries-img-bx{
                    height: 70px;
                }
            }
        }
    }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 570px) {
    .d-col-radio-field{
        flex-direction: column;
        align-items: flex-start;
        .form-check{
            margin-top: 5px;
        }
    }
    .hotel-stays-row{
        .hotel-list-item {
            .hotel-price-block { 
                .date-pos{
                    margin-bottom: 0.5rem;
                    width: 100%;
                    p{
                        text-align: center;
                    }
                }
            }
        }
    }
    .multi-city-row{
        flex-wrap: wrap;
        .multi-city-item{
            width: 100%;
        }
    }
    .d-nav2-tabs{
        margin-right: -5px;
        .nav-item{
            width: 50%;
            margin-top: 5px;
            padding-right: 5px;
            .add-day-t{
                span{
                    font-size: 11px;
                }
            }
        }
    }
    .d-nav-tabs{
        margin-right: -5px;
        .nav-item{
            padding-right: 5px;
            margin-top: 5px;
            width: 50%;
        }
    }
    .d-col-number-group{
        display: block;
        .d-col-number-field{
            max-width: 100%;
            margin-top: 0.5rem;
        }
    }
    .add-destination-item{
        .a-d-left-bx{
            width: 100%;
            .a-d-icon{
                display: none;
            }
            .a-d-item-info{
                width: 100%;
                padding-left:3px;
                h5{
                    font-size: 15px;
                }
                p{
                    font-size: 13px;
                }
            }
        }
        .a-d-item-actions {
            .btn{
                margin-left: 5px;
                width: 34px;
                height: 34px;
                border-width: 1px;
            }
        }
    } 
    .ci-iti-modal-footer {
        .food-footer-rt{
            display: block;
        }
    }
}

/* Custom, iPhone Retina */ 
@media only screen and (max-width : 360px) {
   
}