.error-page-container {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
        font-size: 5rem;
        font-weight: 700;
        color: #004aad;
        line-height: 5rem;
    }

    h5 {
        text-transform: uppercase;
        font-size: 1.1rem;
        font-weight: 600;
        color: #333;
    }
    p{
        margin-top: 0.5rem;
        font-size: 0.9rem;
        color: #555;
        font-weight: 500;
    }

    .btn {
        margin-top: 1.5rem;
        padding: 0.5rem 1rem;
        border: none;
        font-size: 0.8rem;
        font-weight: 500;
        cursor: pointer;
    }
}
