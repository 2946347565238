.navbar {
    .navbar-menu-wrapper {
      transition: width .25s ease;
      -webkit-transition: width .25s ease;
      -moz-transition: width .25s ease;
      -ms-transition: width .25s ease;
      color: #9c9fa6;
      padding-left: 20px;
      padding-right: 20px;
      margin-left: 260px;
      width: calc(100% - 260px);
      height: 68px;
      background-color: #fff;
      // border-bottom: 1px solid #dfe5fa;
      box-shadow: 0px 4px 50px rgb(0 0 0 / 15%);
      position: relative;
      .logo {
        width: 100px;
        height: 80px;
        top: -10px;
        left: 0;
        padding: 10px;
      }
      .search-field{
        .form-control{
          border-radius: 0;
          border: 0;
          background: #fff;
          border-top: 1px solid rgb(231 231 231 / 58%);
          border-bottom: 1px solid rgb(211 211 211 / 30%);
          width: 320px;
          height: 46px;
          font-size: 15px;
          &::placeholder {
            color: #929292;
            opacity: 1; 
          }
          &:-ms-input-placeholder {
            color: #929292;
          }
          &::-ms-input-placeholder {
            color: #929292;
          }
        }
      }
      .d-logout-btn{
        background-color: #FFFFFF;
        //box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
        border-radius: 0.25rem;;
        height: 36px;
        background-image: url('/assets/images/logout-icon.png');
        background-position: center left 8px;
        background-repeat: no-repeat;
        background-size: 16px;
        margin-left: 15px;
        font-size: 14px;
        font-weight: 500;
        padding-left: 26px;
        &:hover{
          background-image: url('/assets/images/logout-icon-white.png');
        }
      }
      .header-wrapper{
        width: 100%;
        .navbar{
          &.custom-nav{
            position: relative !important;
            box-shadow: none !important;
          }
          .container-lg{
            max-width: 100%;
            padding-left: 0;
            padding-right: 0;
            justify-content: flex-end;
          }
        }
      }
    }
  }
  .navbar-toggler-admin{
    color: #000;
    &:focus{
      outline: 0;
      box-shadow: none;
    }
  }
  // @media (min-width: 992px){
  //   .sidebar-icon-only{
  //     .navbar {
  //       .navbar-menu-wrapper {
  //         width: calc(100% - 70px);
  //       }
  //     }
  //   }
  // }
  
  @media only screen and (max-width : 991px) {
    .navbar {
      .navbar-menu-wrapper {
        padding-left: 15px;
        padding-right: 15px;
        margin-left: 0;
        width: 100%;
        height: 70px;
        .search-field{
          .form-control{
            width: 200px;
            height: 44px;
            font-size: 16px;
          }
        }
      }
    }
  
  }