.preview-booking-pane{
    box-shadow: 0 4px 4px #e5e5e5;
    border-radius: 0.25rem;
    border: 1px solid #ddd;
    > h4{
        color: #14183E;
        font-size: 18px;
        font-weight: 600;
        border-bottom: 1px solid #ddd;
        padding: 12px 15px;
    }
    .preview-booking-body{
        padding: 15px;
        .preview-hotel-item{
            display: flex;
            .preview-hotel-img-block{
                overflow: hidden;
                border-radius: 0.25rem;
                img{
                    max-width: 110px;
                    min-width: 110px;
                    min-height: 100%;
                    max-height: 100%;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    border-radius: 0;
                }
            }
            .preview-hotel-info-block{
                width: calc(100% - 110px);
                padding-left: 1rem;
                h5{
                    color: #14183E;
                    font-size: 16px;
                    font-weight: 700;
                }
                p{
                    font-weight: 500;
                    color: #5E6282;
                    font-size: 14px;
                    margin-top: 0.3rem;
                }
                .staring-box {
                    font-size: 13px;
                    color: #ff9800;
                    margin-bottom: 0.3rem;
                }
            }
        }
        .preview-hotelcheck-details{
            padding: 15px;
            background-color: #efefef;
            border-radius: 0.25rem;
            .dayout-box{
                span {
                    color: #5E6282;
                    font-size: 12px;
                }
                p {
                    color: #14183E;
                    font-size: 14px;
                    padding: 0.1rem 0;
                    font-weight: 600;
                    margin: 0;
                }
            }
        }
        .preview-booking-guest{
            padding: 15px;
            background-color: #efefef;
            border-radius: 0.25rem;
            .booking-guest-box{
                span {
                    color: #5E6282;
                    font-size: 12px;
                    display: block;
                }
                p {
                    color: #14183E;
                    font-size: 14px;
                    padding: 0.1rem 0;
                    font-weight: 600;
                    margin: 0;
                }
            }
        }
        .preview-booking-guest-view{
            border-color: #ddd;
            border-radius: 0.25rem;
            .table{
                margin-bottom: 0;
                tbody{
                    th{
                        font-weight: 600;
                        font-size: 14px;
                        color: #14183E;
                        padding: 8px;
                        border-bottom-color:#ddd;
                        background-color: #fff;
                    }
                    td{
                        font-weight: 400;
                        font-size: 14px;
                        color: #14183E;
                        border-color: #ddd;
                        background-color: #fff;
                        border-bottom: 0;
                        padding: 8px;
                    }
                }
            }
        }
        .preview-sub-total{
            h6{
                font-size: 14px;
                font-weight: 500;
            }
        }
        .preview-total{
            border-top: 1px solid #ddd;
            padding: 10px 0 0 0;
            margin-top: 10px;
            span{
                color: #14183E;
                font-size: 15px;
                font-weight: 600;
            }
        }
    }
}