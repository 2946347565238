// Footer styles start
.subscribe-box {
    width: 700px;
    position: relative;
    z-index: 2;
    display: flex;
    margin-top: 20px;

    .email-icon {
        width: 22px;
        height: 22px;
        position: absolute;
        left: 16px;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 1;
        background: url("/assets/images/email-icon.png") center no-repeat;
        background-size: contain;
    }

    .form-control {
        border: 1px solid #D7D7D7;
        border-radius: 0.25rem;
        box-shadow: none;
        height: 60px;
        padding-left: 50px;
        font-weight: 400;
        font-size: 16px;
    }

    .btn {
        border: 0;
        border-radius: 0.25rem;
        font-weight: 500;
        margin-left: 16px;
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        -ms-transition: all 0.3s ease-in-out;
        -o-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        min-width: 140px;
        font-size: 17px;
    }
}

.flex-subscribe {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #FFFFFF;
    box-shadow: 0px -25px 26px rgba(0, 0, 0, 0.02), 0px 64.8148px 46.8519px rgba(0, 0, 0, 0.0151852), 0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0121481), 0px 20px 13px rgba(0, 0, 0, 0.01), 0px 8.14815px 6.51852px rgba(0, 0, 0, 0.00785185), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.00481481);
    border-radius: 20px;
    padding: 30px;
    position: relative;

    .subscribe-shap {
        position: absolute;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;

        &.subscribe-shap-1 {
            background-image: url('/assets/images/shape-1.png');
            background-position: left bottom;
            left: 0;
            bottom: 0;
        }

        &.subscribe-shap-2 {
            background-image: url('/assets/images/shape-2.png');
            background-position: right center;
            right: 0;
            bottom: 0;
        }
    }

    .subscribe-shap-3 {
        position: absolute;
        right: -20px;
        top: -20px;
        width: 74px;
        height: 74px;
        background: url('/assets/images/shape-3.png') no-repeat center;
        z-index: 1;
    }
}

.subscribe-info {
    position: relative;
    z-index: 2;
    max-width: 700px;

    h3 {
        font-weight: 700;
        color: #14183E;
        text-align: center;
        font-size: 24px;
    }
}

.subscribe-input-field {
    position: relative;
    width: 100%;
}

.ft-hold-wrapper {
    background-color: #fff;
    text-align: center;
}

.ft-links {
    margin-top: 15px;
    margin-bottom: 15px;

    li {
        font-weight: 500;
        color: #14183E;
        display: inline-block;
        margin-left: 15px;
        margin-right: 15px;
        font-size: 16px;

        a {
            color: #14183E;

            &:hover {
                color: #004aad;
            }
        }
    }
}

.ft-social-icons {
    .btn {
        display: inline-block;
        width: 44px;
        height: 44px;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 10px;
        margin-left: 10px;
        background-color: #FFFFFF;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 50%;

        &.facebook-icon {
            background-image: url('/assets/images/facebook-icon.png');

            &:hover {
                background-image: url('/assets/images/facebook-icon-white.png');
            }
        }

        &.instagram-icon {
            background-image: url('/assets/images/instagram-icon.png');

            &:hover {
                background-image: url('/assets/images/instagram-icon-white.png');
            }
        }

        &.twitter-icon {
            background-image: url('/assets/images/twitter-icon.png');

            &:hover {
                background-image: url('/assets/images/twitter-icon-white.png');
            }
        }

        &:hover {
            border-radius: 50%;
            // background-color: #004aad;
            background-color: #004aad;
        }
    }
}

.ft-logo {
    margin-top: 30px;
    display: inline-block;
}

.ft-logo-info p {
    font-size: 16px;
    color: #D6D6D6;
    max-width: 340px;
}

.copyright-txt {
    font-weight: 400;
    color: #A5A5A5;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 15px;

    a {
        color: #A5A5A5;
    }
}

// Footer styles end

// Media Query all
@media (min-width: 992px) {}

@media (min-width: 1400px) {}

/* Medium Devices, Desktops */
@media only screen and (max-width : 991px) {

    // Footer styles 
    .ft-links {
        li {
            font-size: 15px;
        }
    }

    .subscribe-info {
        h3 {
            font-size: 22px;
        }
    }

    .copyright-txt {
        font-size: 14px;
    }

    .flex-subscribe {
        .subscribe-shap {
            &.subscribe-shap-1 {
                background-size: 240px;
            }

            &.subscribe-shap-2 {
                background-size: 140px;
            }
        }

        .subscribe-shap-3 {
            right: -10px;
            top: -10px;
            width: 54px;
            height: 54px;
            background-size: contain;
        }
    }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 767px) {
    .ft-links {
        margin-top: 20px;
        margin-bottom: 20px;

        li {
            font-size: 14px;
            margin: 5px 0;
            display: block;
        }
    }

    .subscribe-info {
        h3 {
            font-size: 17px;
        }
    }

    .subscribe-box {
        width: 100%;
        margin-top: 15px;

        .email-icon {
            left: 10px;
            background-size: contain;
            width: 20px;
        }

        .form-control {
            height: 50px;
            padding-left: 35px;
        }

        .btn {
            height: 50px;
            min-width: 105px;
            font-size: 15px;
            margin-left: 10px;
        }
    }

    .copyright-txt {
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .flex-subscribe {
        padding: 20px;

        .subscribe-shap {
            &.subscribe-shap-1 {
                background-size: 140px;
            }

            &.subscribe-shap-2 {
                background-size: 90px;
            }
        }

        .subscribe-shap-3 {
            display: none;
        }
    }

    .ft-social-icons {
        .btn {
            width: 40px;
            height: 40px;
        }
    }
}

/* Custom, iPhone Retina */
@media only screen and (max-width : 570px) {}