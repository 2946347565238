.chk-hotel-right-pane{
    box-shadow: 0 4px 4px #e5e5e5;
    border-radius: 5px;
    border:1px solid #ddd;
    .hotel-round{
        padding: 12px 15px;
        .fare-box-title{
            margin-bottom: 0.5rem;
            h4{
                color: #14183E;
                font-size: 15px;
                font-weight: 600;
            }
        }
        .room-check-in,
        .room-check-out{
            span{
                margin-bottom: 5px;
                font-size: 14px;
                font-weight: 500;
                display: inline-block;
                &.time-build{
                    margin-top: 5px;
                    margin-bottom: 0;
                }
            }
            .date-build {
                font-size: 14px;
                line-height: 20px;
                font-weight: 600;
                color: #333;
                margin-bottom: 0px;
                white-space: nowrap;
            }

            &.text-right{
                text-align: right;
            }
        }
    }
    .accordion{
        .accordion-item{
            border-left: 0;
            border-right: 0;
            &:first-of-type {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
            .accordion-button{
                color: #14183E;
                font-size: 15px;
                font-weight: 600;
                padding: 12px 15px;
                &:focus{
                    box-shadow: none;
                }
                &::after{
                    background-size: 1rem;
                }
            }
        }
        .accordion-body{
            padding: 12px 15px;
        }
    }
    .chk-sub-total{
        padding: 12px 15px;
        .sub-total{
            color: #14183E;
            font-size: 16px;
            font-weight: 600;
        }
    }
    .chk-base-text-item{
        margin-bottom: 0.3rem;
        &:last-child{
            margin-bottom: 0;
        }
        .base-hotel-text{
            color: #333;
            font-size: 14px;
            font-weight: 600;
        }
        .sub-text{
            font-weight: 500;
            color: #555;
            font-size: 14px;
            text-align: right;
        }
    }
}

.chk-hotel-list{
    box-shadow: 0 4px 4px #e5e5e5;
    border-radius: 0.25rem;
    border:1px solid #ddd;
    .chk-hotel-item{
        display: flex;
        .chk-hotel-img-block{
            overflow: hidden;
            border-radius: 0.25rem 0 0 0.25rem;
            img{
                max-width: 90px;
                min-height: 100%;
                max-height: 100%;
                object-fit: cover;
                width: 100%;
                height: 100%;
                border-radius: 0;
            }
        }
        .chk-hotel-info-block{
            width: calc(100% - 90px);
            padding: 10px 10px 10px 12px;
            h5{
                color: #14183E;
                font-size: 14px;
                font-weight: 700;
                font-family: "Montserrat", sans-serif;
            }
            p{
                font-weight: 500;
                color: #5E6282;
                font-size: 12px;
                font-family: "Montserrat", sans-serif;
            }
            .staring-box {
                font-size: 13px;
                color: #ff9800;
                margin-top: 0.2rem;
                margin-bottom: 0.3rem;
                font-family: "Montserrat", sans-serif;
            }
        }
    }
}

.at-action-btns{
    .btn{
        font-size: 13px;
    }
}