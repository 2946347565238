.sidebar {
    // min-height: calc(100vh - 0px);
    border: 1px solid rgba(131, 124, 124, 0.2);
    border-left: 0;
    background-color: #004182;
    // background: radial-gradient(128.84% 111.14% at -6.23% 0%, #FFFFFF 0%, rgba(232, 232, 232, 0.71) 100%);
    padding: 0;
    width: 260px;
    z-index: 1031;
    position: fixed;
    top: 0;
    bottom: 0;
    transition: width .25s ease,background .25s ease;
    -webkit-transition: width .25s ease,background .25s ease;
    -moz-transition: width .25s ease,background .25s ease;
    -ms-transition: width .25s ease,background .25s ease;
    border-radius: 0 5px 5px 0;
    overflow-x: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background: #004182;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(10, 102, 194, 0.5);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(10, 102, 194, 1);
    }
    .btn-back{
        color: #fff;
        position: absolute;
        left: 0;
        top: 0;
        font-size: 18px;
        z-index: 1;
        &:focus{
          box-shadow: none;
        }
    }
    .btn-close{
      margin-right: 1.25rem;
      margin-top: 1rem;
    }
    .nav {
      overflow: hidden;
      flex-wrap: nowrap;
      flex-direction: column;
      &:not(.sub-menu) {
        padding-bottom: 30px;
      }
  
      .nav-item {
        margin:0 1.25rem;
        -webkit-transition-duration: .25s;
        transition-duration: .25s;
        transition-property: background;
        -webkit-transition-property: background;
        .ReactCollapse--collapse {
          transition: height 500ms;
        }
        .collapse {
          z-index: 999;
        }
  
        .nav-link {
          display: inline-flex;
          align-items: center;
          white-space: nowrap;
          padding:0.6rem;
          -webkit-transition-duration: .45s;
          transition-duration: .45s;
          transition-property: color;
          -webkit-transition-property: color;
          cursor: pointer;
          font-weight: 500;
          letter-spacing: 0.5px;
          color: #fff;
          position: relative;
          width: 100%;
          background-color: rgb(10 102 194/40%);
          border-radius: 0.25rem;
          margin-top: 0.5rem;
          i {
            color: inherit;
            &.menu-icon {
              width: 24px;
              height: 24px;
              background-repeat: no-repeat;
              background-position: center;
              margin-right: 5px;
              opacity: 0.6;
              &.icon-dashboard{
                background-image: url('/assets/images/icon-dashboard-white.png');
                background-size: 18px;
              }
              &.icon-settings{
                background-image: url('/assets/images/icon-settings-white.png');
                background-size: 18px;
              }
              &.icon-password{
                background-image: url('/assets/images/icon-password-white.png');
                background-size: 15px;
              }
              &.icon-iteneries{
                background-image: url('/assets/images/icon-itenaries.png');
                background-size: 16px;
              }
              &.icon-stays{
                background-image: url('/assets/images/icon-stays-white.png');
                background-size: 16px;
              }
              &.icon-cabs{
                background-image: url('/assets/images/icon-cabs-white.png');
                background-size: 16px;
              }
              &.icon-flight{
                background-image: url('/assets/images/icon-flight-white.png');
                background-size: 17px;
              }
            }
  
            &.menu-arrow {
              position: absolute;
              right: 0.6rem;
              top: 0;
              bottom: 0;
              width: 14px;
              height: 14px;
              font-size: 14px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin: auto;
              transition: transform .2s ease-in-out;
              + .menu-icon {
                margin-left: .25rem;
                margin-bottom: .25rem;
                .rtl & {
                  margin-left: 0;
                  margin-right: .25rem;
                }
              }
            }
          }
  
          .menu-title {
            color: inherit;
            display: inline-block;
            font-size:13px;
            line-height: 1;
            vertical-align: middle;
            opacity: 0.8;
          }
  
          &.menu-expanded {
            .menu-arrow{
                transform: rotate(-180deg);
            }
          }
        }
  
        &.active {
          .nav-link {
            background-color: #004aad;
            .menu-title {
              color:#fff;  
              opacity: 1;    
            }
            i {
              &.menu-icon {
                opacity: 1;
                &.icon-dashboard{
                  background-image: url('/assets/images/icon-dashboard-white.png');
                }
                &.icon-settings{
                  background-image: url('/assets/images/icon-settings-white.png');
                }
                &.icon-password{
                  background-image: url('/assets/images/icon-password-white.png');
                }
                &.icon-iteneries{
                  background-image: url('/assets/images/icon-itenaries-hover.png');
                }
                &.icon-stays{
                  background-image: url('/assets/images/icon-stays-white.png');
                }
                &.icon-cabs{
                  background-image: url('/assets/images/icon-cabs-white.png');
                }
                &.icon-flight{
                  background-image: url('/assets/images/icon-flight-white.png');
                }
              }
            }
          }
        }
  
        &:hover{
          background: transparent;
          .nav-link{
            background-color: #004aad;
            color: #fff;
            .menu-title{
              color: #fff;
              opacity: 1;
            }
          }
        }
        &.nav-profile{
          text-align: center;
          .nav-link{
            padding: 1.25rem 0 1rem 0;
            border-bottom: 1px solid rgba(131, 124, 124, 0.4);
            flex-direction: column;
            width: 100%;
            background-color: transparent;
            margin-bottom: 0;
          }
          .nav-profile-image{
            width: 100px;
            height: 100px;
            background: #fff;
            border: 1px solid #B8B8F5;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            position: relative;
            img{
              width: 90px;
              height: 90px;
              border-radius: 50%;
              object-fit: cover;
            }
            .upload-profileimage-btn {
                z-index: 1;
                position: absolute;
                top: 10px;
                right: 0;
                overflow: hidden;
                font-size: 14px;
                color: #004aad;
                background-color: #FFFFFF;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                input {
                    position: absolute;
                    font-size: 30px;
                    opacity: 0;
                    right: 0;
                    top: 0;
                }
            }
          }
          .nav-profile-text{
            display: flex;
            flex-direction: column;
            width: 100%;
            .text-medium{
              font-weight: 500;
              font-size: 15px;
              color: #fff;
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .text-small{
              font-weight: 400;
              font-size: 13px;
              letter-spacing: 0.5px;
              color: rgb(255, 255, 255, 50%);
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
  
      &:not(.sub-menu) {
        > .nav-item {
          &:hover {
            &:not(.nav-category):not(.nav-profile) {
              > .nav-link {
                color: #fff;
              }
            }
          }
        }
      }
      &.sub-menu {
        margin-top:0.5rem;
        list-style: none;
        margin-left: 0;
        .nav-item {
          padding: 0;
          margin-left: 0;
          margin-right: 0;
          .nav-link {
            color: #fff;
            padding:0.6rem;
            position: relative;
            font-size: 13px;
            line-height: 1;
            height: auto;
            border-top: 0;
            background-color: transparent;
            margin-top: 0;
            &.active {
              color: #fff;
              background-color: #004aad;
            }
            &:hover {
              color: #fff;
              background-color: #004aad;
            }
          }
          &:hover {
            background: transparent;
          }
        }
      }
    }
  }
  .pricerange-minmax{
    display: flex;
    justify-content: space-between;
    span{
        font-size: 13px;
    }
  }
  .date-accordian-bx{
    .react-datepicker {
      width: 100%;
      .react-datepicker__month-container{
        width: 100%;
      }
      .react-datepicker__day-name, 
      .react-datepicker__day, 
      .react-datepicker__time-name {
        width: 1.5rem;
        line-height: 1.5rem;
      }
    }
  }
  .filter-list-ul{
    max-height: 200px;
    overflow-x:hidden;
    overflow-y: auto;
  }
  
  /* style for off-canvas menu*/
  @media screen and (max-width: 991px) {
    .sidebar{
      border-radius: 0;
      min-height: auto;
    }
    .sidebar-offcanvas {
      position: fixed;
      height: 100vh;
      top: 0;
      bottom: 0;
      overflow: auto;
      right: -260px;
      -webkit-transition: all 0.25s ease-out;
      -o-transition: all 0.25s ease-out;
      transition: all 0.25s ease-out;
      z-index: 99999;
      &.active {
        right: 0;
      }
    }
    .sidebar-overlay{
      &.active{
        background-color: rgba(0,0,0,.5);
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 9999;
        -webkit-transition: all 0.25s ease-out;
        -o-transition: all 0.25s ease-out;
        transition: all 0.25s ease-out;
      }
    }
    .sidebar{
      .nav {
        .nav-item{
          &.nav-profile{
            .nav-link{
              padding-top: 0;
            }
          }
        }
      }
    } 
    
  }