.flighttravellers-frm-rw{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .flighttravellers-frm-label{
        color: #666;
        font-size: 14px;
        width: calc(100% - 100px);
        span{
            font-style: italic;
        }
    }
    .form-select{
        max-width: 100px;
    }
}
.flightclasstype-select{
    border: 1px solid rgba(10, 102, 194, 0.3);
    border-radius: 0.25rem;
    height: 50px;
    font-size: 16px;
    color: #14183E;
}
.flight-h-block{
    display: flex;
    justify-content: space-between;
    background: #fff;
    border-top: 1.3px solid #2d3e50;
    padding: 15px;
    .flight-h-left{
        h5{
            color: #333333;
            font-size: 15px;
            font-weight: 600;
        }
        p{
            font-size: 13px;
            color: #333333;
            margin-top: 0.3rem;
        }
    }
    .flight-h-right{
        .btn{
            font-size: 14px;
            margin-left: 0.8rem;
        }
    }
}
.flight-result-block{
    background-color: rgba(10, 102, 194, 0.1);
    .flight-filter-left{
        width: 260px;
        .flight-filter-header{
            background: #fff;
            padding: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            span{
                font-size: 14px;
                text-transform: uppercase;
                color: #333333;
            }
            .btn{
                font-size: 13px;
                padding: 2px 8px;
            }
        }
        .accordion{
            margin-top: 15px;
            .accordion-item{
                border: 0;
                border-bottom: 1px solid #ccc;
                .accordion-button{
                    padding: 10px;
                    font-size: 14px;
                    text-transform: uppercase;
                    color: #333333;
                    &:after{
                        background-size: 16px;
                    }
                    &:focus{
                        box-shadow: none;
                    }
                    &:not(.collapsed){
                        background-color: #fff;
                        box-shadow: inset 0 -1px 0 #ccc;
                    }
                }
            }
        }
    }
    .flight-filter-right{
        width: calc(100% - 260px);
        padding-left: 1.5rem;
        .filter-top-block{
            display: flex;
            margin-left: -5px;
            margin-right: -5px;
            .top-block-item{
                padding: 0 5px;
                width: 25%;
                .top-block-span{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-color: #fff;
                    padding: 5px 5px;
                    .af-btn{
                        color: #fff; 
                        padding: 3px 5px; 
                        border-radius: 3px; 
                        font-size: 11px;
                        display:block;
                        &.cor-1{
                            background-color: green; 
                        }
                        &.cor-2{
                            background-color: #ff9900; 
                        }
                        &.cor-3{
                            background-color: #ff0000; 
                        }
                        &.cor-4{
                            background-color: #0014ff; 
                        }
                    }
                    .text{
                        color: #333;
                        font-size: 11px;
                    }
                }
            }
        }
        .flight-sort-block{
            margin-top: 15px;
            display: flex;
            background-color: #fff;
            .sort-block-item{
                padding: 5px;
                width: 25%;
                text-align: center;
                .sort-block-asc{
                    font-size: 12px;
                    color: #333;
                    display: inline-block;
                    span{
                        display: inline-block;
                        i{
                            line-height: 0;
                            display: block;
                        }
                        &.text{
                            margin-right: 0.5rem;
                        }
                    }
                }
            }
        }
    }
}
.flight-search-list-item{
    margin-top: 20px;
    display: flex;
    background: #fff;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%), 0 3px 1px -2px rgb(0 0 0 / 20%);
    .flsl-left{
        width: 75%;
        .logo-area{
            .logo-sec{
                padding-top: 10px;
                padding-left: 10px;
            }
            .flight-name{
                padding-left: 10px;
                .txt-small{
                    font-size: 12px;
                }
            }
        }
        .dtl-area{
            padding-top: 10px;
            padding-bottom: 10px;
            .timing{
                text-align: center;
                .times{
                    font-size: 18px;
                    color:#555;
                    font-weight: 600;
                }
                .dest{
                    font-size: 14px; 
                    color:#333;
                }
                .txt-small{
                    font-size: 12px;
                }
            }
        }
        .details-fare-sec{
            border-top: 1px dashed #ccc;
            justify-content: center;
            li{
                color: #2d3e50;
                font-size: 11px;
                padding: 10px;
                text-transform: uppercase;
                display: inline-block;
            }
        }
    }
    .flsl-right{
        width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .flsl-prices{
            text-align: center;
            padding: 5px 10px 15px;
            border-left:1px solid #ccc;
            height: 100%;
            .price{
                font-size: 16px;
                font-weight: 600;
                color:#004aad;
                padding-bottom: 5px;
            }
            .total-price{
                font-size: 80%;
                font-weight: 400;
                color: #000;
            }
            .total-passan{
                font-size: 80%;
                font-weight: 400;
                color:#004aad;
            }
        }
        .book-btn{
            .btn{
                height: 38px;
                border-radius: 0;
                font-size: 15px;
                text-align: center;
                width:100%;
            }
        }
    }
}