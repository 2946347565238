
.header-wrapper{
    position: relative;
}
.fixed-navbar{
    // .logo {
    //     width: 100px;
    //     height: 80px;
    //     top: 0;
    // }
    .custom-nav{
        box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.15);
    }
}
.navbar-right-box{
    display: flex;
    align-items: center;
}
.logo{
    width: 124px;
    height: 90px;
    background-color: #ffffff;
    box-shadow: 0px 10px 12px #A9C0F0;
    border-radius: 0px 0px 5px 5px;
    position: absolute;
    top: -10px;
    left: .75rem;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
}
.custom-nav{
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #fff;
    min-height: 68px;
    position: fixed !important;
    top: 0!important;
    left: 0;
    right: 0;
    z-index: 999;
    .container-lg{
        justify-content: flex-end;
        position: relative;
    }
}
.login-links{
    display: flex;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
    a{
        font-weight: 500;
        font-size: 14px;
        color: #444444;
        // margin-left: 8px;
        // padding-right: 8px;
        display: inline-block;
        min-width: 80px;
        i{
            display: none;
        }
        &:hover{
            color: #004aad;
        }
        &:focus{
            box-shadow: none;
        }
    }
    .divided-line{
        color: #444444;
        font-size: 16px;
        margin-left: 8px;
    }
}
.btn.btn-enquire{
    border: 1px solid transparent;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 14px;
    color: #444444;
    text-align: center;
    &:hover{
        color: #004aad;
    }
    &:focus{
        box-shadow: none;
    }
    i{
        display: none;
    }
}
.headerDropdown-moretravel{
    .dropdown-toggle{
        background-position: center left 8px;
        background-repeat: no-repeat;
        background-image: url('/assets/images/more-travel-icon.png');
        background-size: 16px;
        border-radius: 50%;
        padding-left: 26px;
        color: #444444;
        font-size: 14px;
        font-weight: 500;
        &:focus{
            box-shadow: none;
        }
    }
    .dropdown-menu{
        background-color: #FFFFFF;
        border-radius: 0.5rem;
        box-shadow: 0px 0.5rem 1rem 0px rgb(0 11 38 / 24%);
        border:0;
        min-width: 180px;
        .dropdown-item{
            padding: 0.5rem 1rem;
            font-weight: 500;
            color: #444444;
            font-size: 14px;
            i{
                font-size: 16px;
                margin-right: 2px;
                width: 18px;
            }
        }
    }
}

.EnquirecustomModal{
    .modal-content{
        border: 0;
        // box-shadow: 0 1rem 3rem rgba(0,0,0,.5);
        .modal-header{
            padding-left: 1rem;
            padding-right: 1rem;
            border-bottom-color: #E5E5E5;
            .modal-title{
                font-weight: 700;
                text-transform: capitalize;
                color: #14183E;
                font-size: 18px;
            }
        }
        .modal-body{
            padding: 1rem;
        }
        .modal-footer{
            padding: 1rem;
        }
    }
}
.headerDropdown-profile{
    margin-right: 8px;
    .btn{
        border: 0;
        padding: 5px 0;
        margin: 0;
        font-size: 15px;
        &:focus{
            box-shadow: none;
        }
        img{
            width: 30px;
            height: 30px;
            object-fit: cover;
            border-radius: 50%;
            border: 2px solid #004aad;
        }
        .profileUserName{
            display: inline-block;
            margin: 0 2px 0 5px;
            font-weight: 500;
            color: #444444;
            font-size: 14px;
        }
    }
    .dropdown-menu{
        background-color: #004aad;
        a{
            font-size: 14px;
            font-weight: 500;
            padding: 0.2rem 0.75rem;
            border-radius: 0px;
            border:0;
        }
    }
}

// Media Query all
@media (min-width: 992px){

}

@media (min-width: 1400px){

}
/* Medium Devices, Desktops */
@media only screen and (max-width : 991px) {
    
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 767px) {
    .logo{
        width: 80px;
        height: 75px;
        padding: 10px;
    }
    .fixed-navbar{
        .logo {
            width: 80px;
            height: 75px;
        }
    }
    .login-links{
        margin-left: 5px;
        margin-right: 5px;
        a{
            min-width: 36px;
            padding: 0;
            span{
                display: none;
            }
            i{
                display: inline-block;
                background-position: center;
                background-repeat: no-repeat;
                width: 32px;
                height: 32px;
                &.h-signin-icon{
                    background-image: url('/assets/images/login.png');
                    background-size: 22px;
                }
                &.h-support-icon{
                    background-image: url('/assets/images/customer-support.png');
                    background-size: 25px;
                }
            }
        }
        .divided-line{
            margin-right: 5px;
        }
    }
    .btn.btn-enquire{
        padding: 0;
        margin-right: 8px;
        height: inherit;
        span{
            display: none;
        }
        i{
            display: inline-block;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 23px;
            width: 32px;
            height: 32px;
            &.h-enquire-icon{
                background-image: url('/assets/images/help.png');
            }
        }
    }
    .headerDropdown-profile{
        .btn {
            .profileUserName{
                display: none;
            }
        }
    } 
    .headerDropdown-moretravel{
        .dropdown-toggle{
            background-position: center left 5px;
            background-size: 24px;
            border-radius: 50%;
            min-width: 46px;
            padding-right: 0;
            span{
                display: none;
            }
        }
    }
}

/* Custom, iPhone Retina */ 
@media only screen and (max-width : 570px) {

}