.accomadation-sortby-block{
    >ul{
        display: flex;
        align-items: center;
        background-color: #004aad;
        border-radius: 0.25rem;
        li{
            width:20%;
            border-right: 1px solid #fff;
            text-align: center;
            padding: 8px 0;
            &:last-child{
                border-right:0;
            }
            span{
                font-size: 12px;
                color: #fff;
                font-weight: 400;
            }
            .btn{
                border-radius: 0;
                padding: 0;
                background-color:transparent;
                font-size: 12px;
                color: #fff;
                font-weight: 400;
                i{
                    font-size: 11px;
                }
                &:focus{
                    box-shadow: none;
                }
            }
        }
    }
}
.stay-search-form-block{
    padding-top: 35px;
    padding-bottom: 35px;
    .search-h-block{
        .form-row {
            flex-wrap: inherit;
            .form-col{
                margin-bottom: 0;
            }
        }
        .stay-form-btns{
            padding-left: 6px;
            padding-right: 6px;
            .btn {
                min-width: 140px;
                font-size:14px;
                height: 50px;
                border-radius: 0.25rem;
                letter-spacing: 0.5px;
                color:#fff;
            }
        }
        .stay-form-input-fields{
            .form-control{
                background-color: #fff;
                border: 1px solid rgb(10 102 194 / 30%);
                border-radius: 0.25rem;
                height: 50px;
                padding-left: 40px;
                background-repeat: no-repeat;
                &.location-icon{
                    background-image: url('/assets/images/location-icon.png');
                    background-position: 12px center;
                    background-size: 14px;
                }
                &.calendar-icon{
                    background-image: url('/assets/images/calendar-icon.png');
                    background-position: 12px center;
                    background-size: 18px;
                }
                &.user-icon{
                    background-image: url('/assets/images/user-icon.png');
                    background-position: 12px center;
                    background-size: 20px;
                }
            }
        }
    }
}
.accomadation-filter-block{
    background-color: #fff;
    padding: 15px 0px;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 15%);
    .accomadation-filter-left{
        display: flex;
        align-items: center;
        .filter-txt{
            font-size: 14px;
            color: #212529;
            font-weight: 400;
        }
        .dropdown{
            margin-left: 0.5rem;
            .dropdown-toggle{
                background-color: #004aad;
                border-color: #004aad;
                font-weight: 400;
                padding: 5px 15px;
                color: #fff;
                font-size: 12px;
            }
            .dropdown-menu{
                color: #656565;
                min-width: 260px;
                padding: 15px 15px;
                border: 0;
                box-shadow: 0 0 10px 1px #d2b4f7;
                .accomadation-filter-drp-title{
                    font-weight: 600;
                    font-size: 14px;
                    margin-bottom: 0.5rem;
                }
                .accomadation-filter-popular{
                    .filter-item{
                        margin-bottom: 0.5rem;
                        display: flex;
                        .form-check-input[type=checkbox] {
                            margin-top: 0.20rem;
                        }
                        .form-check-label{
                            font-size: 14px;
                            padding-left: 6px;
                            width:calc(100% - 16px);
                        }
                    }
                }
            }
        }
        .btn.btn-reset{
            background-color: #ff1818;
            border-color: #ff1818;
            font-weight: 400;
            padding: 5px 15px;
            color: #fff;
            font-size: 12px;
            margin-left: 0.5rem;
        }
    }
}
.accomadation-filter-right{
    .react-search-field{
        border-radius: 0.25rem;
        height: 38px!important;
        .react-search-field-input{
            border-top-left-radius:0.25rem;
            border-bottom-left-radius:0.25rem;
            font-weight: 400 !important;
            width: 200px;
            height: 36px !important;
        }
        .react-search-field-button{
            border-top-right-radius:0.25rem;
            border-bottom-right-radius:0.25rem;
            height: 36px!important;
        }
    }
}
.accomadation-sidebar{
   .map-div{
        border-radius: 0.25rem;
        box-shadow: 0 4px 4px #e5e5e5;
        .top-map-view-block{
            border-radius: 0;
        }
    }
    .hybrid-map-div{
        background-color: #eff3f7;
        padding: 10px 15px;
        border-radius: 0px 0px 5px 5px;
        a{
            font-size: 14px;
            font-weight: 500;
            font-family: "Montserrat", sans-serif;
            i{
                margin-right: 5px;
                font-size: 16px;
            }
        }
    }
}
.accomadation-sidebar-title{
    color: #14183E;
    font-weight: 600;
    font-size: 16px;
    font-family: "Montserrat", sans-serif;
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 991px) {
   
    .stay-search-form-block{
        .search-h-block{
            .form-row {
                flex-wrap: wrap;
                .form-col{
                    width: 50%;
                    margin-bottom:10px;
                }
            }
            .stay-form-btns{
                width: 100%;
                text-align: center;
                .btn {
                   display: inline-block;
                }
            }
        }
    }
    .accomadation-filter-right{
        .react-search-field{
            .react-search-field-input{
                width: 175px;
            }
        }
    }
    
   
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 767px) {
    
    .accomadation-sortby-block{
        >ul{
            flex-wrap: wrap;
            li{
                width:50%;
                border-bottom: 1px solid #fff;
                &:last-child{
                    width: 100%;
                    border-bottom:0;
                }
            }
        }
    }
    .accomadation-filter-block{
        .accomadation-filter-left{
            flex-wrap: wrap;
            position: relative;
            padding-left: 40px;
            .filter-txt{
                position: absolute;
                left: 0;
                padding-bottom: 0.5rem;
            }
            .dropdown{
                margin-bottom: 0.5rem;
            }
            .btn.btn-reset{
                margin-bottom: 0.5rem;
            }
        }
    }
    .accomadation-filter-right{
        .react-search-field{
            width: 100%;
        }
    }
   
}

/* Custom, iPhone Retina */ 
@media only screen and (max-width : 570px) {
   
    .stay-search-form-block{
        .search-h-block{
            .form-row {
                .form-col{
                    width: 100%;
                }
            }
            .stay-form-btns{
                .btn {
                   height: 44px;
                }
            }
        }
    }
  
}

@media only screen and (max-width : 370px) {
    
}